import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { setQuoteData } from 'redux/ducks/quote';

import OONAssociates from 'services/rest/oon_associates';
import Settings from 'services/rest/settings';
import Firms from 'services/rest/firms';
import { roundDecimals, roundUp } from 'services/strings';

import RadioButton from 'com/ui/RadioButton';
import Loader from 'com/ui/Loader';
import DataTable from 'com/widgets/DataTable';
import StarsRating from 'com/widgets/StarsRating';
import Checkbox from 'com/ui/Checkbox';
import EditAssociateRatesModal from 'com/widgets/QuoteModals/EditAssociateRatesModal';
import PricePoints from 'com/widgets/PricePoints';
import QuoteAllAssociatesModal from 'com/widgets/QuoteModals/QuoteAllAssociatesModal';
import CreateAgentModal from 'com/widgets/CreateAgentModal';

import ICON_APPROVE_ACTION from 'assets/images/icons/ico_checked.svg';
import ICON_INFO from 'assets/images/icons/ico_info_grey.svg';

import { RATE_CONFIRMED_BY, QB_NUM_OF_ASSOC_DISPLAYED } from 'data/constants';

import ADD_ASSOCIATES from 'assets/images/icons/ico_add_associates.svg';
import SEARCH_ASSOCIATES from 'assets/images/icons/ico_see_all_associates.svg';
import SWAP_ASSOCIATE from 'assets/images/icons/ico_swap_agent.svg';
import EventLoggerEvents from 'services/rest/event_logger';
import './style.css';

const AssociateRecommender = (props) => {
	const tableHeadersInit = [
		{ title: 'Region', field: 'region', type: 'string', sort: 'none', sortable: false },
		{ title: 'Language', field: 'language', type: 'string', sort: 'none', sortable: false },
		{ title: 'Agent', field: 'associate', type: 'string', sort: 'none', sortable: false },
		{ title: 'Filing fee', field: 'filing_fee', type: 'string', sort: 'none', sortable: false },
		{ title: 'Translation fee', field: 'translation_fee', type: 'string', sort: 'none', sortable: false },
		{ title: 'Official fee', field: 'offical_fee', type: 'string', sort: 'none', sortable: false },
		{ title: 'total', field: 'total_fees', type: 'string', sortable: true, sort: 'up' },
		{ title: 'service', field: 'rating', type: 'string', sort: 'none', sortable: true },
		{ title: 'Price', field: 'price', type: 'string', sort: 'none', sortable: false },
		{ title: 'Reciprocity', field: 'reciprocity', type: 'string', sort: 'none', sortable: false },
		{ title: '', field: 'actions', type: 'string', sort: 'none', sortable: false },
		{ title: '', field: 'actions', type: 'string', sort: 'none', sortable: false },
	];

	const quote = useSelector((state) => state.quote);
	const dispatch = useDispatch();
	const [data, setData] = useState([]);
	const [dataCache, setDataCache] = useState([]);
	const [associatesData, setAssociatesData] = useState([]);
	const [currencySymbol, setCurrencySymbol] = useState('');
	const [showAllAssociatesModal, setShowAllAssociatesModal] = useState(false);
	const [associateModalData, setAssociateModalData] = useState([]);
	const [editRateForm, setEditRateForm] = useState(false);
	const [currencies, setCurrencies] = useState([]);
	const [loader, setLoader] = useState(false);
	const [createAgentModal, setCreateAgentModal] = useState(false);
	const [regionsForAgentsModal, setRegionsForAgentsModal] = useState([]);
	const [regionCodeForNewAgent, setRegionCodeForNewAgent] = useState('');
	const [regionForNewAgentIsCountry, setRegionForNewAgentIsCountry] = useState(true);
	const [regionIdForNewAgent, setRegionIdForNewAgent] = useState('');
	const [rate, setRate] = useState({
		currency: 'USD',
		rate_professional: 0,
		rate_translation: 0,
		rate_translation_type: 'WORD',
		service: '',
		available: 0,
		firm_id: '',
		rate_id: '',
		rate_confirmed_by: '',
	});
	const [tableHeaders, setTableHeaders] = useState(tableHeadersInit);
	const [filterData, setFilterData] = useState({
		orderBy: [{ field: 'total_fees', direction: 'up' }],
	});
	const [swapAssociateId, setSwapAssociateId] = useState('');

	useEffect(() => {
		(async () => {
			setLoader(true);
			try {
				await fetchData();
				// We are using the exchange rates for currencies here since we need only code from the currency.
				// And currencies that are already implemented in the system (are in use and updated regularly)
				// To limit the choice to implemented currencies
				let cs = await Settings.GetExchangeRates();
				if (cs) {
					setCurrencies(cs.map((c) => ({ label: c.code, value: c.code })));
				}
				setRegionsForAgentsModal([{ label: '', value: '' }, ...props.regions.map((r) => ({ label: r.name, value: r.code, id: r.id, is_country: r.is_country }))]);
				setLoader(false);
			} catch (error) {
				setLoader(false);
				console.log(error);
			}
		})();
	}, []);

	const fetchData = async () => {
		let filter = {
			regions: quote.regions,
			service_id: quote.service_id,
		};

		let firm = await Firms.GetOneByID(quote.client_firm_id);
		let currencyCode = '';
		if (firm.currency_id) {
			let c = props.currencies.find((c) => c.id === firm.currency_id);
			currencyCode = c.code;
			setCurrencySymbol(c.symbol);
		}

		let [res, mr, tr] = await Promise.all([
			OONAssociates.QuoteAssociateRecommender(quote),
			Settings.GetAllMarketRatesByRegionsAndServiceID({ ...filter, currency: currencyCode }),
			Settings.GetTranslationRequests({ regions: quote.regions }),
		]);
		if (res) {
			let associates = Object.groupBy(res, ({ region_id }) => region_id);
			let data = quote.regions.map((r) => {
				let region = props.regions.find((rt) => rt.id === r);
				let translationRequest = tr.find((t) => t.region_id === region.id);
				let language = props.languages.find((l) => l.value === translationRequest?.primary_language_id);
				let associate = associates[region.id] || [];
				associate = associate.map((a) => {
					// if (a.firm_id === '0') {
					//     a.region_name = region.name;
					//     a.region_code = region.code;
					// }
					if (a.firm_id !== '0') {
						if (a.rate_professional === 0) {
							a.rate_professional = mr.find((r) => r.region_id === region.id)?.rate_professional || 0;
							a.rate_confirmed_by = RATE_CONFIRMED_BY.MARKET;
						}
						if (a.rate_translation === 0 && a.is_translation_applied) {
							a.rate_translation = mr.find((r) => r.region_id === region.id)?.rate_translation || 0;
							a.rate_confirmed_by = RATE_CONFIRMED_BY.MARKET;
						}
					}
					return a;
				});
				if (associate.length > 0) {
					associate = sortAssociates(associate, 'total_fees', 'up');
				}
				return {
					region_id: region.id,
					region_name: region.name,
					region_code: region.code,
					language: language ? language.label : 'English',
					associates: associate,
				};
			});
			setData(data);
			setDataCache(data);
			setAssociatesData(associates);
		}
	};

	const columnSort = (col) => {
		setTableHeaders(
			tableHeaders.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (filterData.orderBy.filter((r) => r.field === col).length === 0) {
						setFilterData({
							...filterData,
							orderBy: [{ field: col, direction: h.sort }, ...filterData.orderBy],
						});
					} else {
						setFilterData({
							...filterData,
							orderBy: [{ field: col, direction: h.sort }, ...filterData.orderBy.filter((c) => c.field !== col)],
						});
					}
					if (data.length > 0) {
						let tmp = data.map((r) => {
							if (r.associates.length > 0) {
								return {
									...r,
									associate: sortAssociates(r.associates, col, h.sort),
								};
							} else {
								return r;
							}
						});
						setData(tmp);
					}
				}
				return h;
			}),
		);
	};

	const setAssociates = (data) => {
		dispatch(setQuoteData({ name: 'additional_region_info', value: data }));
	};

	const radioUpdate = (e) => {
		let region_id = e.target.closest('tr').dataset.region;
		const selectedRegion = dataCache.find((region) => region.region_id === region_id);
		const selectedLanguage = selectedRegion.language;
		const updatedData = data.map((region) => {
			if (region.language === selectedLanguage) {
				return {
					...region,
					associates: region.associates.map((associate) => {
						if (region.region_id === region_id && e.target.value === associate.firm_id) {
							return associate;
						} else {
							return {
								...associate,
								rate_translation: 0,
							};
						}
					}),
				};
			} else {
				return region;
			}
		});

		const finalData = updatedData.map((region) => {
			if (region.region_id === region_id) {
				return {
					...region,
					associates: region.associates.map((associate) => {
						if (associate.firm_id === e.target.value) {
							const originalAssociate = selectedRegion.associates.find((a) => a.firm_id === e.target.value);
							return {
								...associate,
								rate_translation: originalAssociate.rate_translation,
							};
						} else {
							return associate;
						}
					}),
				};
			} else {
				return region;
			}
		});

		const updatedAssociatesData = finalData.reduce((acc, fd) => {
			acc[fd.region_id] = fd.associates.map((a) => ({ ...a, language: selectedRegion.language }));
			return acc;
		}, {});

		setData(finalData);
		setAssociatesData(updatedAssociatesData);

		selectAssociate(region_id, e.target.value, updatedAssociatesData);
		if (!props.selectedRegionsForInstruct.includes(region_id)) {
			props.setSelectedRegionsForInstruct([...props.selectedRegionsForInstruct, region_id]);
		}
	};

	const selectAssociate = (region_id, firm_id, data) => {
		let a = quote.additional_region_info.find((a) => a.region_id === region_id);
		if (a.firm_id !== firm_id) {
			let associate = data[region_id].find((a) => a.firm_id === firm_id);
			let tmp_associates = quote.additional_region_info.map((r) => {
				if (r.region_id === region_id) {
					return {
						...r,
						firm_id: firm_id,
						associate_id: associate.associate_id,
						rate_official: roundUp(associate.rate_official),
						rate_professional: roundUp(associate.rate_professional),
						rate_translation: roundUp(associate.rate_translation),
						total: roundUp(associate.rate_translation) + roundUp(associate.rate_professional) + roundUp(associate.rate_official),
						language: associate.language,
					};
				}
				return r;
			});
			const updatedTmpAssociates = tmp_associates.map((a) => {
				if (a.language === associate.language) {
					if (a.region_id === region_id) {
						return a;
					} else {
						return {
							...a,
							rate_translation: 0,
							total: associate.rate_professional + associate.rate_official,
						};
					}
				}
				return a;
			});
			setAssociates(updatedTmpAssociates);
		}
	};

	const processChecked = (region_id, firm_id) => {
		let data = quote.additional_region_info.find((s) => s.region_id === region_id && s.firm_id === firm_id);
		if (data) {
			return true;
		}
		return false;
	};

	const getTotal = (associates) => {
		let price = 0;
		associates.map((a) => {
			price = price + a.total;
		});
		return price.toFixed(2);
	};

	const calculateAzamiTotal = (data) => {
		let price = 0;
		data.map((r) => {
			if (r.associates.length > 0) {
				let azami = r.associates.find((a) => a.firm_name === 'Azami');
				if (azami) {
					price = price + azami.rate_official + azami.rate_professional + azami.rate_translation;
				}
			}
		});
		return price.toFixed(2);
	};

	const showEditRateForm = (e) => {
		let rate_id = e.target.closest('div').dataset.rates;
		let region_id = e.target.closest('tr').dataset.region;
		let associate = associatesData[region_id].find((a) => a.rate_id === rate_id);
		let service = props.services.find((s) => s.id === associate.rate_service_id);
		setRate({
			available: associate.rate_available,
			firm_id: associate.firm_id,
			rate_id: associate.rate_id,
			service: service.name,
			rate_professional: associate.rate_professional,
			rate_translation: associate.rate_translation,
			currency: associate.currency,
			rate_translation_type: associate.rate_translation_type,
			rate_confirmed_by: RATE_CONFIRMED_BY.CLIENT,
		});
		setEditRateForm(true);
	};

	const hideEditRateForm = () => {
		setEditRateForm(false);
	};

	const updateRateChange = (e) => {
		if (e.target.name === 'rate_availability') {
			if (rate.available === 1) {
				setRate({
					...rate,
					available: 0,
				});
			} else {
				setRate({
					...rate,
					available: 1,
				});
			}
		} else {
			setRate({
				...rate,
				[e.target.name]: e.target.value,
			});
		}
	};

	const submitRateUpdate = async () => {
		let data = {
			rate_translation: rate.rate_translation,
			rate_professional: rate.rate_professional,
			rate_translation_type: rate.rate_translation_type,
			currency: rate.currency,
			rate_confirmed_by: RATE_CONFIRMED_BY.CLIENT,
			available: rate.available,
		};
		setLoader(true);
		setEditRateForm(false);
		try {
			await Firms.UpdateRateById(rate.rate_id, data);
			await fetchData();
			EventLoggerEvents.EventLogger({ event: 'Client Updates rate from Playground', data: { user: props.user } });
			setLoader(false);
		} catch (error) {
			setLoader(false);
			console.log(error);
		}
	};

	const showAllAssociatesModalClose = () => {
		setShowAllAssociatesModal(false);
	};

	const handleSearchAssociateClick = (e) => {
		let region_id = e.target.closest('tr').dataset.region;
		let region = data.find((s) => s.region_id === region_id);
		setAssociateModalData(region.associates);
		setShowAllAssociatesModal(true);
		if (e.target.parentElement && e.target.parentElement.dataset.associate) setSwapAssociateId(e.target.parentElement.dataset.associate);
		EventLoggerEvents.EventLogger({ event: 'See All available OON Assoc for a service/region', data: { user: props.user } });
	};

	const showAllAssociatesActions = [{ primary: true, label: 'Cancel', action: showAllAssociatesModalClose, theme: 'azami-ghost' }];

	const closeCreateAgentModal = () => {
		setCreateAgentModal(false);
	};

	const openCreateAgentModal = (e) => {
		let region_id = e.target.closest('tr').dataset.region;
		let region_code = e.target.closest('tr').dataset.code;
		let region = regionsForAgentsModal.find((r) => r.id === region_id);
		if (region.is_country) {
			setRegionForNewAgentIsCountry(true);
		} else {
			setRegionForNewAgentIsCountry(false);
		}
		setRegionCodeForNewAgent(region_code);
		setRegionIdForNewAgent(region_id);
		setCreateAgentModal(true);
	};

	const sortAssociates = (data, field, direction) => {
		if (field === 'total_fees') {
			if (direction === 'up') {
				data = data.sort((a, b) => {
					if (!a.is_active) {
						return 1;
					}
					if (a.rate_professional + a.rate_translation + a.rate_official > b.rate_professional + b.rate_translation + b.rate_official) {
						if (!a.is_favorite && b.is_favorite) return 1;
						else return -1;
					} else {
						if (a.is_favorite && !b.is_favorite) return -1;
						else return 1;
					}
				});
			}
			if (direction === 'down') {
				data = data.sort((a, b) => b.rate_professional + b.rate_translation + b.rate_official - (a.rate_professional + a.rate_translation + b.rate_official));
			}
		}

		if (field === 'rating') {
			if (direction === 'up') {
				data = data.sort((a, b) => a.rating - b.rating);
			}
			if (direction === 'down') {
				data = data.sort((a, b) => b.rating - a.rating);
			}
		}
		return data;
	};

	return (
		<div className="quote-details__section-container">
			{loader ?
				<div className="services_technologies-loader">
					<div className="services_technologies-loader__positioner">
						{loader ?
							<Loader theme="purple" />
						:	null}
					</div>
				</div>
			:	null}
			{data.length > 0 ?
				<div>
					<DataTable customClassName="associate-recommender-table" fixedHeader={true} columns={tableHeaders} onColumnSort={columnSort}>
						{data.map((r) => {
							return (
								<>
									{r.associates.length > 0 ?
										<>
											{r.associates.slice(0, QB_NUM_OF_ASSOC_DISPLAYED).map((a, i) => {
												let isBundleRegionRow = i == 0;
												let totalAgents = Math.min(r.associates.length, QB_NUM_OF_ASSOC_DISPLAYED);
												let trClass = `
                                                    ${isBundleRegionRow ? 'bundle-region-row' : ''} 
                                                    ${a.is_active ? '' : 'inactive'} 
                                                    ${a.is_favorite ? 'favorite' : ''}`;

												return (
													<tr key={r.region_code + i} data-id={r.id} data-code={r.region_code} data-region={r.region_id} class={trClass}>
														{isBundleRegionRow ?
															<>
																<DataTable.CountryCell code={r.region_code || ''} rowspan={totalAgents}>
																	{r.region_code}
																</DataTable.CountryCell>
																<DataTable.Cell rowspan={totalAgents}>{r.language}</DataTable.Cell>
															</>
														:	''}
														<DataTable.Cell>
															<div data-associate={a.firm_id} key={`${r.region_code}_${i}`}>
																<RadioButton
																	label={a.firm_name}
																	customClassName="quote-details__service-list-service"
																	name={`${r.region_code}_${a.firm_name}`}
																	value={a.firm_id}
																	checked={processChecked(r.region_id, a.firm_id)}
																	onChange={radioUpdate}
																	themeCheck={'blurple'}
																/>
															</div>
														</DataTable.Cell>
														<DataTable.Cell>{`${currencySymbol}${roundUp(a.rate_professional)}`}</DataTable.Cell>
														<DataTable.Cell>{`${currencySymbol}${roundUp(a.rate_translation)}`}</DataTable.Cell>
														<DataTable.Cell>{`${currencySymbol}${roundUp(a.rate_official) || 0}`}</DataTable.Cell>
														<DataTable.Cell>
															<div key={`total_fee_${i}`} className="associate-row-pricing" data-rates={a.rate_id}>
																{a.firm_id === '0' ?
																	<span className={'update-button-gray update-button'}>
																		<img src={ICON_APPROVE_ACTION} title="Azami rates" />
																	</span>
																: a.rate_confirmed_by === RATE_CONFIRMED_BY.CLIENT ?
																	<span className={'update-button-gray update-button'}>
																		<img src={ICON_APPROVE_ACTION} title="Client confirmed rates" />
																	</span>
																: a.rate_confirmed_by === RATE_CONFIRMED_BY.MARKET ?
																	<span className={'update-button-gray update-button'} onClick={showEditRateForm}>
																		<img src={ICON_INFO} title="This agent has not provided rates. Click here to add rates" />
																	</span>
																:	<span className={'update-button-gray update-button'}>
																		<img src={ICON_APPROVE_ACTION} title="Agent confirmed rates" />
																	</span>
																}
																{`${currencySymbol}${(roundUp(a.rate_professional) + roundUp(a.rate_translation) + roundUp(a.rate_official)).toFixed(0)}`}
															</div>
														</DataTable.Cell>
														<DataTable.Cell>
															<StarsRating rating={a.firm_id !== '0' ? a.rating : 0} />
														</DataTable.Cell>
														<DataTable.Cell>
															<PricePoints
																length={5}
																value={a.firm_id !== '0' ? roundDecimals(a.points?.value, 2) : 0}
																threshold={a.firm_id !== '0' ? a.points?.threshold : 0}
															/>
														</DataTable.Cell>
														<DataTable.Cell>{a.firm_id !== '0' ? `${a.sent_cases}:${a.received_cases}` : 'N/A'}</DataTable.Cell>
														<DataTable.Cell>
															<span className="associate-row-icon-container" data-associate={a.firm_id}>
																<img title="See more agents" src={SWAP_ASSOCIATE} onClick={handleSearchAssociateClick} alt="" />
															</span>
														</DataTable.Cell>

														{isBundleRegionRow ?
															<>
																<DataTable.Cell rowspan={totalAgents}>
																	<span className="associate-row-icon-container">
																		<img title="Add agents" src={ADD_ASSOCIATES} onClick={openCreateAgentModal} alt="" />
																	</span>
																</DataTable.Cell>
															</>
														:	''}
													</tr>
												);
											})}
										</>
									:	<>
											<tr key={r.region_code} data-id={r.id} data-code={r.region_code} data-region={r.region_id} class="no-agents-region-row">
												<DataTable.CountryCell code={r.region_code || ''}>{r.region_code}</DataTable.CountryCell>
												<DataTable.Cell>{r.language}</DataTable.Cell>
												<DataTable.Cell colspan={8}>You have no agents in this region. You can invite a new agent using the icon at the right of the screen.</DataTable.Cell>
												<DataTable.Cell>
													<span className="associate-row-icon-container">
														<img title="Add agents" src={ADD_ASSOCIATES} onClick={openCreateAgentModal} alt="" />
														<img title="See more agents" src={SEARCH_ASSOCIATES} onClick={handleSearchAssociateClick} alt="" />
													</span>
												</DataTable.Cell>
											</tr>
										</>
									}
								</>
							);
						})}
					</DataTable>
				</div>
			:	null}
			<div className="total-prices">
				<div className="total-prices-container">
					<div className="total-prices-span-header">Total</div>
					<div className="total-prices-selected">
						<div className="total-prices-span-first">Total Estimate (when using selected associates):</div>
						<div className="total-prices-span">{`${currencySymbol}${getTotal(quote.additional_region_info)}`}</div>
					</div>
					{/* <div className='total-prices-azami'>
                        <div className='total-prices-span-first'>
                            Total Estimate (when using only Azami associates):
                        </div>
                        <div className='total-prices-span'>
                            {`${currencySymbol}${calculateAzamiTotal(data)}`}
                        </div>
                    </div> */}
				</div>
			</div>
			{editRateForm ?
				<EditAssociateRatesModal currencies={currencies} data={rate} handleChange={updateRateChange} closeHandler={hideEditRateForm} submitRateUpdate={submitRateUpdate} />
			:	null}
			{showAllAssociatesModal ?
				<QuoteAllAssociatesModal
					footerActions={showAllAssociatesActions}
					closeHandler={showAllAssociatesModalClose}
					associateData={associateModalData}
					setAssociateModalData={setAssociateModalData}
					selectAssociate={selectAssociate}
					data={data}
					setData={setData}
					tableHeaders={tableHeaders}
					columnSort={columnSort}
					selectedRegionsForInstruct={props.selectedRegionsForInstruct}
					setSelectedRegionsForInstruct={props.setSelectedRegionsForInstruct}
					swapAssociateId={swapAssociateId}
					fromPlayGround={true}
					currencySymbol={currencySymbol}
				/>
			:	null}
			{createAgentModal ?
				<CreateAgentModal
					clientFirmId={quote.client_firm_id}
					closeHandler={closeCreateAgentModal}
					countries={regionsForAgentsModal}
					redirect={false}
					createRegionRate={true}
					activeFirm={true}
					regionId={regionIdForNewAgent}
					regionCode={regionCodeForNewAgent}
					regionForNewAgentIsCountry={regionForNewAgentIsCountry}
					disableFirmCoutry={true}
					refreshData={fetchData}
					services={props.services}
					serviceId={quote.service_id}
					fromPlayground={true}
				/>
			:	null}
		</div>
	);
};

export default AssociateRecommender;
